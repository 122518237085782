import { all, call, delay, put, takeLatest } from 'redux-saga/effects'
import { history } from 'index'
import {
  changeEmail,
  confirmEmail,
  load,
  login,
  recoverPassword,
  register,
  resendEmail,
  resetPassword,
  loginSessionVerify,
} from 'api/user'
import store from 'store'
import MenuAction from 'redux/menu/actions'
import { register as registerBusiness } from 'api/business'
import RootAction from 'redux/actions'
import actions from './actions'
import { userStateData } from './reducers'

export function* REGISTER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      registering: true,
    },
  })
  const { response, error } = yield call(register, payload)
  store.set(`user.email`, payload.email)
  yield put({
    type: actions.SET_STATE,
    payload: {
      email: payload.email,
      registering: false,
    },
  })
  if (response) {
    history.push('/confirm-email')
  } else {
    const { errors } = error.response?.data
    yield put({
      type: actions.SET_ERROR_STATE,
      payload: {
        register: errors,
      },
    })
  }
}

export function* CHANGE_EMAIL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      changingEmail: true,
    },
  })
  const { response, error } = yield call(changeEmail, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      changingEmail: false,
    },
  })
  if (response) {
    store.set(`user.email`, payload.email)
    yield put({
      type: actions.SET_STATE,
      payload: {
        email: payload.new_email,
        displayChangeEmailModal: false,
      },
    })
  } else {
    const { errors } = error.response?.data
    yield put({
      type: actions.SET_ERROR_STATE,
      payload: {
        change: errors,
      },
    })
  }
}

export function* RESEND_EMAIL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      canResendEmail: false,
      resendingEmail: true,
    },
  })
  const { response } = yield call(resendEmail, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      resendingEmail: false,
    },
  })
  if (response) {
    yield delay(10000)
    yield put({
      type: actions.SET_STATE,
      payload: {
        canResendEmail: true,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        canResendEmail: true,
      },
    })
  }
}

export function* CONFIRM_EMAIL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      confirming: true,
    },
  })
  const { response } = yield call(confirmEmail, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      confirming: false,
    },
  })
  if (response) {
    const { access_token } = response.data
    yield call(HANDLE_USER_TOKEN, access_token)
    yield call(LOAD_CURRENT_ACCOUNT)
  }
}

function* HANDLE_USER_TOKEN(access_token) {
  store.set(`user.accessToken`, access_token)
  yield put({
    type: actions.SET_STATE,
    payload: {
      accessToken: access_token,
      email: null,
      canResendEmail: false,
      resendEmail: false,
    },
  })
  history.push('/')
}

export function* LOGIN({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loggingIn: true,
    },
  })
  const { response } = yield call(login, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loggingIn: false,
    },
  })
  if (response) {
    const { access_token } = response.data
    yield call(HANDLE_USER_TOKEN, access_token)
    yield call(LOAD_CURRENT_ACCOUNT)
  }
}

export function* LOGIN_VERIFY({ payload }) {
  console.log('login payload', payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loggingIn: true,
    },
  })
  const { response } = yield call(loginSessionVerify, payload)
  console.log('response login verify', response)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loggingIn: false,
    },
  })
  if (response) {
    const { access_token_verify } = response.data
    yield call(HANDLE_USER_TOKEN, access_token_verify)
    yield call(LOAD_CURRENT_ACCOUNT)
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  if (!store.get(`user.accessToken`)) return
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(load)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  if (response) {
    const { user, staff, businesses, can_register } = response.data
    if (staff && !staff.is_active) {
      yield call(LOGOUT)
    } else {
      store.set(`user.data`, user)
      store.set(`user.canRegister`, can_register)
      if (staff) {
        store.set(`user.staff`, staff)
      }
      yield put({
        type: actions.SET_STATE,
        payload: {
          data: user,
          staff,
          canRegister: can_register,
        },
      })
      if (businesses && businesses.length > 0) {
        const [business] = businesses
        store.set(`user.business`, business)
        yield put({
          type: actions.SET_STATE,
          payload: {
            business,
          },
        })
        if (business.locations.length > 0) {
          const [location] = business.locations
          yield call(SET_LOCATION, { payload: { location } })
        }
      }
    }
  }
}

export function* SET_LOCATION({ payload }) {
  const { location, refresh } = payload
  store.set(`user.location`, location)
  store.set(`user.timezone`, location.timezone)
  yield put({
    type: actions.SET_STATE,
    payload: {
      location,
    },
  })
  yield put({
    type: MenuAction.SET_DATA,
  })
  if (refresh) {
    history.replace('/manage/gift-card')
  }
}

export function* RECOVER_PASSWORD({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      recovering: true,
    },
  })
  const { response, error } = yield call(recoverPassword, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      recovering: false,
    },
  })
  if (response) {
    history.push('/')
  } else {
    const { errors } = error.response?.data
    yield put({
      type: actions.SET_ERROR_STATE,
      payload: {
        recover: errors,
      },
    })
  }
}

export function* RESET_PASSWORD({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      resetting: true,
    },
  })
  const { response, error } = yield call(resetPassword, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      resetting: false,
    },
  })
  if (response) {
    const { access_token } = response.data
    yield call(HANDLE_USER_TOKEN, access_token)
    yield call(LOAD_CURRENT_ACCOUNT)
  } else {
    const { errors } = error.response?.data
    yield put({
      type: actions.SET_ERROR_STATE,
      payload: {
        reset: errors,
      },
    })
  }
}

export function* LOGOUT() {
  yield call(CLEAR_USER)
}

export function* CLEAR_USER() {
  store.clearAll()
  yield put({
    type: RootAction.CLEAR_STATE,
  })
  yield put({
    type: actions.SET_STATE,
    payload: userStateData,
  })
}

export function* REGISTER_BUSINESS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      submitting: true,
    },
  })
  const { response, error } = yield call(registerBusiness, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      submitting: false,
    },
  })
  if (response) {
    yield call(LOAD_CURRENT_ACCOUNT)
  } else {
    const { errors } = error.response?.data
    yield put({
      type: actions.SET_ERROR_STATE,
      payload: {
        business: errors,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.REGISTER, REGISTER),
    takeLatest(actions.CHANGE_EMAIL, CHANGE_EMAIL),
    takeLatest(actions.RESEND_EMAIL, RESEND_EMAIL),
    takeLatest(actions.CONFIRM_EMAIL, CONFIRM_EMAIL),
    takeLatest(actions.LOGIN, LOGIN),
    takeLatest(actions.LOGIN_VERIFY, LOGIN_VERIFY),
    takeLatest(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeLatest(actions.RECOVER_PASSWORD, RECOVER_PASSWORD),
    takeLatest(actions.RESET_PASSWORD, RESET_PASSWORD),
    takeLatest(actions.LOGOUT, LOGOUT),
    takeLatest(actions.CLEAR_USER, CLEAR_USER),

    takeLatest(actions.REGISTER_BUSINESS, REGISTER_BUSINESS),
    takeLatest(actions.SET_LOCATION, SET_LOCATION),

    LOAD_CURRENT_ACCOUNT(),
  ])
}
