import apiClient from 'services/axios'

const BASE_URL = 'emailpromotion'

export async function create(payload) {
  return apiClient
    .post(`${BASE_URL}/create`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateemail(payload) {
  return apiClient
    .post(`${BASE_URL}/modify`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function remove(payload) {
  return apiClient
    .post(`${BASE_URL}/delete`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getemailbystatus(payload) {
  return apiClient
    .post(`${BASE_URL}/getbystatus`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getemailhistory() {
  return apiClient
    .get(`${BASE_URL}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
