import { createSelector } from 'reselect'
import { selectCountryData } from '../country/selectors'

export const selectUser = state => state.user

export const selectUserData = createSelector([selectUser], user => {
  if (user.search && user.data) {
    return user.data.filter(
      o => o.name.includes(user.search) || o.user?.email?.includes(user.search),
    )
  }
  return user.data
})

export const selectStaff = createSelector([selectUser], user => user.staff)

export const selectBusiness = createSelector([selectUser], user => user.business)

export const selectCurrency = createSelector([selectBusiness], business => business?.currency)

export const selectBusinessSubscription = createSelector(
  [selectBusiness],
  business => business?.subscription,
)

export const selectBusinessFreeGiftCard = createSelector(
  [selectBusiness],
  business => business?.free_physical_card_count,
)

export const selectBusinessUsedFreeGiftCard = createSelector(
  [selectBusiness],
  business => business?.used_free_physical_card_count,
)

export const selectBusinessCountry = createSelector(
  [selectBusiness, selectCountryData],
  (business, country) => {
    if (business && country) {
      const location = business.locations?.find(o => o.is_primary)
      if (location) return country.find(o => o.name === location.country)
    }
    return null
  },
)

export const selectLocation = createSelector([selectUser], user => user.location)

export const selectAvailableLocations = createSelector(
  [selectStaff, selectBusiness],
  (staff, business) => {
    if (!staff || !business) return []
    if (staff.role === 'owner') return business.locations
    return staff.locations
  },
)

export const selectCanViewGiftCardStat = createSelector(
  [selectStaff, selectLocation],
  (staff, location) => {
    if (!staff || !location) return false
    return staff.role === 'owner'
  },
)

export const selectCanIssueGiftCard = createSelector(
  [selectStaff, selectLocation],
  (staff, location) => {
    if (!staff || !location) return false
    return (
      (location.is_primary || location.permission?.issue_gift_card) &&
      (staff.role === 'owner' || staff.permission.issue_card)
    )
  },
)

export const selectCanReloadGiftCard = createSelector(
  [selectStaff, selectLocation],
  (staff, location) => {
    if (!staff || !location) return false
    return (
      (location.is_primary || location.permission?.reload_gift_card) &&
      (staff.role === 'owner' || staff.permission.reload_card)
    )
  },
)

export const selectCanRedeemGiftCard = createSelector(
  [selectStaff, selectLocation],
  (staff, location) => {
    if (!staff || !location) return false
    return (
      (location.is_primary || location.permission?.redeem_gift_card) &&
      (staff.role === 'owner' || staff.permission.redeem_card)
    )
  },
)

export const selectCanDoCashSale = createSelector(selectStaff, staff => {
  if (!staff) return false
  return staff.role === 'owner' || staff.permission.cash_sale
})

export const selectCanManageCustomer = createSelector(selectStaff, staff => {
  if (!staff) return false
  return staff.role === 'owner' || staff.permission.manage_customer
})

export const selectCanManageUser = createSelector(
  [selectStaff, selectLocation],
  (staff, location) => {
    if (!staff || !location) return false
    return (
      (location.is_primary || location.permission?.manage_user) &&
      (staff.role === 'owner' || staff.permission.manage_user)
    )
  },
)

export const selectCanManageCoupon = createSelector(
  [selectStaff, selectLocation],
  (staff, location) => {
    if (!staff) return false
    return staff.role === 'owner' && location.is_primary
  },
)

export const selectCanRunReport = createSelector(
  [selectStaff, selectLocation],
  (staff, location) => {
    if (!staff || !location) return false
    return (
      (location.is_primary || location.permission?.run_report) &&
      (staff.role === 'owner' || staff.permission.run_report)
    )
  },
)

export const selectCanAccessSetup = createSelector(
  [selectStaff, selectLocation],
  (staff, location) => {
    if (!staff) return false
    return staff.role === 'owner' && location.is_primary
  },
)
