import actions from './actions'

const initialState = {
  theArray: [],
  theArray2: [],
  lastDeleted: null,
  list: null,
  data: null,
  business: null,
  location: null,
  textColors: null,
  defaultImages: null,
  digitalGiftCard: null,
  physicalGiftCard: null,
  importTemplateUrl: null,
  loading: false,
  updating: false,
  displayChooseImageModal: false,
  uploading: false,
  error: false,
}

export default function settingReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.UPDATE_IMAGE:
      return {
        ...state,
        data: {
          ...state.data,
          gift_card: {
            ...state.data.gift_card,
            strip_image: action.payload.image.image,
            strip_image_url: action.payload.image.image_url,
            custom_images: [...state.data.gift_card.custom_images, action.payload.image],
          },
        },
        displayChooseImageModal: false,
      }

    case actions.ADD_DEFAULT:
      return {
        ...state,
        theArray: [...state.theArray, action.payload],
      }

    case actions.ADD_DEFAULT2:
      return {
        ...state,
        theArray2: [...state.theArray2, action.payload],
      }

    case actions.UPDATE_DEFAULT:
      return {
        ...state,
        error: false,
        theArray2: action.payload,
      }

    default:
      return state
  }
}
