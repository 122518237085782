export const cardTypes = {
  digital: 'Digital Card',
  physical: 'Physical Card',
}

export const transactionTypes = {
  purchase: 'Purchase',
  reload: 'Reload',
  redeem: 'Redeem',
}

export const paymentMethods = {
  cash: 'Cash',
  card: 'Card',
  converge: 'Card',
  clover: 'Card',
}

export const giftCardAmount = [
  {
    key: '1',
    name: '1',
    hidden: true,
  },
  {
    key: '2',
    name: '2',
    hidden: true,
  },
]

export const staffRoles = [
  {
    key: 'owner',
    name: 'Owner',
    hidden: true,
  },
  {
    key: 'manager',
    name: 'Manager',
  },
  {
    key: 'employee',
    name: 'Employee',
  },
]

export const channels = {
  'in-store': 'In-Store',
  'online-store': 'Online Store',
  clover: 'Clover',
  poynt: 'Poynt',
}
