import PhoneNumber from 'awesome-phonenumber'
import moment from 'moment-timezone'
import store from 'store'

export const parseType = (type, types) => {
  return types[type]
}

export const phoneNumberSignificant = data => {
  if (data) {
    const phoneNumber = new PhoneNumber(data, 'CA') // todo: auto detect
    return phoneNumber.getNumber('significant')
  }
  return null
}

export const phoneNumberNational = data => {
  if (data) {
    const phoneNumber = new PhoneNumber(data, 'CA') // todo: auto detect
    return phoneNumber.getNumber('national')
  }
  return null
}

export const phoneNumberE164 = data => {
  if (data) {
    const phoneNumber = new PhoneNumber(data, 'CA') // todo: auto detect
    return phoneNumber.getNumber('e164')
  }
  return null
}

export const getFormattedDate = dateTime => {
  const timezone = store.get(`user.timezone`) || 'UTC'
  if (dateTime) return moment.tz(dateTime, timezone).format('MMM D, YYYY')
  return '-'
}

export const getFormattedDateTime = (dateTime, shouldFormat) => {
  const timezone = store.get(`user.timezone`) || 'UTC'
  if (shouldFormat) {
    return moment.tz(dateTime, timezone).format('MMM D, YYYY hh:mm a')
  }
  return moment.tz(dateTime, timezone).fromNow()
}
