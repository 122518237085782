import apiClient from 'services/axios'

const BASE_URL = 'business'

export async function register(payload) {
  console.log('business payload', payload)
  return apiClient
    .post(`${BASE_URL}/register`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadLogo(business, payload) {
  return apiClient
    .post(`${BASE_URL}/${business}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createSubscription(business, payload) {
  return apiClient
    .post(`${BASE_URL}/${business}/subscription`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
