import apiClient from 'services/axios'

const BASE_URL = 'invoice'

export async function loadAll() {
  return apiClient
    .get(`${BASE_URL}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function detail(id) {
  return apiClient
    .get(`${BASE_URL}/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
