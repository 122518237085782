import React from 'react'
import Loading from '../Loading'

const PageLoader = () => {
  return (
    <div className="d-flex">
      <Loading />
    </div>
  )
}

export default PageLoader
