import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  addCard,
  changeDefaultCard,
  create,
  loadPlanInventory,
  purchaseGiftCard,
  removeCard,
} from 'api/subscription'
import actions from './actions'

export function* LOAD_PLAN_INVENTORY() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(loadPlanInventory)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  if (response) {
    const { subscription, inventory, billing } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        data: subscription,
        inventory,
        billing,
      },
    })
  }
}

export function* CREATE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(create, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    yield call(LOAD_PLAN_INVENTORY)
    window.location.reload()
  }
}

export function* ADD_CARD({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      addingCard: true,
    },
  })
  const { response } = yield call(addCard, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      addingCard: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayAddCardModal: false,
      },
    })
    yield call(LOAD_PLAN_INVENTORY)
  }
}

export function* CHANGE_DEFAULT_CARD({ payload: { id } }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      changingDefaultCard: true,
    },
  })
  const { response } = yield call(changeDefaultCard, id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      changingDefaultCard: false,
    },
  })
  if (response) {
    yield call(LOAD_PLAN_INVENTORY)
  }
}

export function* REMOVE_CARD({ payload: { id } }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      removingCard: true,
    },
  })
  const { response } = yield call(removeCard, id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      removingCard: false,
    },
  })
  if (response) {
    yield call(LOAD_PLAN_INVENTORY)
  }
}

export function* PURCHASE_GIFT_CARD({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      purchasingGiftCard: true,
    },
  })
  const { response } = yield call(purchaseGiftCard, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      purchasingGiftCard: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayPurchaseCardDrawer: false,
      },
    })
    yield call(LOAD_PLAN_INVENTORY)
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_PLAN_INVENTORY, LOAD_PLAN_INVENTORY),
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.ADD_CARD, ADD_CARD),
    takeEvery(actions.CHANGE_DEFAULT_CARD, CHANGE_DEFAULT_CARD),
    takeEvery(actions.REMOVE_CARD, REMOVE_CARD),
    takeEvery(actions.PURCHASE_GIFT_CARD, PURCHASE_GIFT_CARD),
  ])
}
