import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { create, getemailbystatus, getemailhistory, remove, updateemail } from 'api/email-marketing'
import actions from './actions'

export function* CREATE_EMAIL({ payload }) {
  const { ...values } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(create, values)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        newlyAdded: response.data,
      },
    })
  }
}

export function* GET_DRAFT_EMAIL({ payload }) {
  console.log('draft list payload', payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      pageLoader: true,
      updatingdraft: true,
    },
  })
  const { response } = yield call(getemailbystatus, payload)
  console.log('draft list response', response)
  yield put({
    type: actions.SET_STATE,
    payload: {
      pageLoader: false,
      updatingdraft: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        data: response.data,
      },
    })
  }
}

export function* UPDATE_EMAIL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
      updatingdraft: true,
    },
  })
  // const { updatedData } = payload
  const { response } = yield call(updateemail, payload)

  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
      updatingdraft: false,
    },
  })
  if (response) {
    yield put({
      type: actions.EDIT_EMAIL,
      payload: {
        data: response.data.data,
      },
    })
  }
}

export function* GET_EMAIL_HISTORY() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      pageLoader: true,
    },
  })
  const { response } = yield call(getemailhistory)
  yield put({
    type: actions.SET_STATE,
    payload: {
      pageLoader: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        data: response.data,
      },
    })
  }
}

export function* REMOVE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: true,
    },
  })
  const { emailpromotionid } = payload

  const { response } = yield call(remove, payload)

  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: false,
    },
  })
  if (response) {
    yield put({
      type: actions.REMOVE_EMAIL,
      payload: {
        emailpromotionid,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE_EMAIL, CREATE_EMAIL),
    takeLatest(actions.GET_DRAFT_EMAIL, GET_DRAFT_EMAIL),
    takeEvery(actions.UPDATE_EMAIL, UPDATE_EMAIL),
    takeLatest(actions.GET_EMAIL_HISTORY, GET_EMAIL_HISTORY),
    takeLatest(actions.REMOVE, REMOVE),
  ])
}
