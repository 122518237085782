import apiClient from 'services/axios'

const BASE_URL = 'payment'

export async function load() {
  return apiClient
    .get(`${BASE_URL}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function convergePin(payload) {
  return apiClient
    .post(`${BASE_URL}/converge/pin`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateConverge(payload) {
  return apiClient
    .put(`${BASE_URL}/converge`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function authClover() {
  return apiClient
    .get(`${BASE_URL}/clover/auth`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateClover(payload) {
  return apiClient
    .put(`${BASE_URL}/clover`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
