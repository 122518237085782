import actions from './actions'

const initialState = {
  data: null,
  selectedData: null,
  updatedata: null,

  creating: false,

  search: '',
  getdraftlist: null,
  pagelimit: 10,
  pagesize: 1,

  displayUpdateEmailDrawer: false,
  updating: false,
  updatingdraft: false,

  pageLoader: false,

  displayRemoveModal: false,
  removing: false,
  errors: {
    update: null,
  },
}

export default function customerReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_ERROR_STATE:
      return { ...state, errors: { ...state.errors, ...action.payload } }

    case actions.EDIT_EMAIL:
      return {
        ...state,
        displayUpdateEmailDrawer: false,
        data: state.data.map(o => {
          if (action.payload.data._id === o._id) {
            return action.payload.data
          }
          return o
        }),
      }
    case actions.REMOVE_EMAIL:
      return {
        ...state,
        displayRemoveModal: false,
        data: state.data.filter(o => action.payload.emailpromotionid !== o._id),
      }
    case actions.SHOW_REMOVE_EMAIL:
      return { ...state, selectedData: action.payload.emailMarketing, displayRemoveModal: true }
    case actions.HIDE_REMOVE_EMAIL:
      return { ...state, displayRemoveModal: false }
    case actions.SHOW_UPDATE_EMAIL:
      return {
        ...state,
        selectedData: action.payload.emailMarketing,
        displayUpdateEmailDrawer: true,
      }
    case actions.HIDE_UPDATE_EMAIL:
      return { ...state, selectedData: null, displayUpdateEmailDrawer: false }
    default:
      return state
  }
}
