import React, { lazy, Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from 'layouts'

const routes = [
  // Auth Pages
  {
    path: '/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/confirm-email',
    Component: lazy(() => import('pages/auth/confirm-email')),
    exact: true,
  },
  {
    path: '/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/login-verify',
    Component: lazy(() => import('pages/auth/login-verify')),
    exact: true,
  },
  {
    path: '/recover-password',
    Component: lazy(() => import('pages/auth/recover-password')),
    exact: true,
  },
  {
    path: '/reset-password',
    Component: lazy(() => import('pages/auth/reset-password')),
    exact: true,
  },
  {
    path: '/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
  // Business Pages
  {
    path: '/business',
    Component: lazy(() => import('pages/business')),
    exact: true,
  },
  // User Pages
  {
    path: '/manage/gift-card',
    Component: lazy(() => import('pages/admin/manage/gift-card')),
    exact: true,
  },
  {
    path: '/manage/customer',
    Component: lazy(() => import('pages/admin/manage/customer')),
    exact: true,
  },
  {
    path: '/manage/user',
    Component: lazy(() => import('pages/admin/manage/staff')),
    exact: true,
  },
  {
    path: '/manage/location',
    Component: lazy(() => import('pages/admin/manage/location')),
    exact: true,
  },
  {
    path: '/manage/coupon',
    Component: lazy(() => import('pages/admin/manage/coupon')),
    exact: true,
  },
  {
    path: '/summary/sale',
    Component: lazy(() => import('pages/admin/summary/sale')),
    exact: true,
  },
  {
    path: '/summary/redeem',
    Component: lazy(() => import('pages/admin/summary/redeem')),
    exact: true,
  },
  {
    path: '/setup/store',
    Component: lazy(() => import('pages/admin/setup/store')),
    exact: true,
  },
  {
    path: '/setup/gift-card',
    Component: lazy(() => import('pages/admin/setup/gift-card')),
    exact: true,
  },
  {
    path: '/setup/integration',
    Component: lazy(() => import('pages/admin/setup/integration')),
    exact: true,
  },
  {
    path: '/billing/plan-inventory',
    Component: lazy(() => import('pages/admin/billing/plan-inventory')),
    exact: true,
  },
  {
    path: '/billing/payment-history',
    Component: lazy(() => import('pages/admin/billing/payment-history')),
    exact: true,
  },
  // Email Marketing
  {
    path: '/email-marketing/create-email',
    Component: lazy(() => import('pages/admin/email-marketing/create-email')),
    exact: true,
  },
  {
    path: '/email-marketing/drafts',
    Component: lazy(() => import('pages/admin/email-marketing/drafts')),
    exact: true,
  },
  {
    path: '/email-marketing/email-history',
    Component: lazy(() => import('pages/admin/email-marketing/email-history')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to="/manage/gift-card" />} />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
