const actions = {
  SET_STATE: 'customer/SET_STATE',
  SET_ERROR_STATE: 'customer/SET_ERROR_STATE',
  EDIT_DATA: 'customer/EDIT_DATA',
  SHOW_UPDATE: 'customer/SHOW_UPDATE',
  HIDE_UPDATE: 'customer/HIDE_UPDATE',

  LOAD: 'customer/LOAD',
  UPDATE: 'customer/UPDATE',
  GET_CUSTOMERS: 'customer/GET_CUSTOMERS',
  GET_CUSTOMERS_LIST: 'customer/GET_CUSTOMERS_LIST',
}

export default actions
