const actions = {
  SET_STATE: 'setting/SET_STATE',
  UPDATE_IMAGE: 'setting/UPDATE_IMAGE',

  LOAD: 'setting/LOAD',
  LOAD_STORE: 'setting/LOAD_STORE',
  UPDATE: 'setting/UPDATE',
  UPDATE_STORE: 'setting/UPDATE_STORE',
  UPLOAD_IMAGE: 'setting/UPLOAD_IMAGE',
  ADD_DEFAULT: 'setting/ADD_DEFAULT',
  ADD_DEFAULT2: 'setting/ADD_DEFAULT2',
  UPDATE_DEFAULT: 'setting/UPDATE_DEFAULT',
}

export default actions
