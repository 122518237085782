import apiClient from 'services/axios'

const BASE_URL = 'setting'

export async function load(payload) {
  return apiClient
    .get(`${BASE_URL}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadsetting(payload) {
  return apiClient
    .get(`${BASE_URL}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadStore(payload) {
  return apiClient
    .get(`${BASE_URL}/store`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function update(payload) {
  return apiClient
    .put(`${BASE_URL}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateStore(payload) {
  return apiClient
    .put(`${BASE_URL}/store`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadImage(payload) {
  return apiClient
    .post(`${BASE_URL}/upload-image`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
