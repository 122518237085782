import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { create, loadAll, update } from 'api/location'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadAll)
  if (response) {
    const { locations } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        data: locations,
      },
    })
  }
}

export function* CREATE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response, error } = yield call(create, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_CREATE,
    })
    yield call(LOAD_ALL)
  } else {
    const { errors } = error.response?.data
    yield put({
      type: actions.SET_ERROR_STATE,
      payload: {
        create: errors,
      },
    })
  }
}

export function* UPDATE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { _id, updatedData } = payload
  const { response, error } = yield call(update, _id, updatedData)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    const { location } = response.data
    yield put({
      type: actions.EDIT_DATA,
      payload: {
        data: location,
      },
    })
  } else {
    const { errors } = error.response?.data
    yield put({
      type: actions.SET_ERROR_STATE,
      payload: {
        update: errors,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.UPDATE, UPDATE),
  ])
}
