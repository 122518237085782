import apiClient from 'services/axios'

const BASE_URL = 'business-type'

export default function loadAll(payload) {
  return apiClient
    .get(`${BASE_URL}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
