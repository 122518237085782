import apiClient from 'services/axios'

const BASE_URL = 'location'

export async function loadAll(payload) {
  return apiClient
    .get(`${BASE_URL}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function create(payload) {
  return apiClient
    .post(`${BASE_URL}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function update(locationId, payload) {
  return apiClient
    .put(`${BASE_URL}/${locationId}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
