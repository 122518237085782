/* eslint-disable camelcase */
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { authClover, convergePin, load, updateClover, updateConverge } from 'api/payment'
import actions from './actions'

export function* LOAD() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      data: null,
      loading: true,
    },
  })
  const { response } = yield call(load)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  if (response) {
    const { payment, images } = response.data.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        data: payment,
        images,
      },
    })
  }
}

export function* CONVERGE_PIN({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      confirmingPassword: true,
    },
  })
  const { response } = yield call(convergePin, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      confirmingPassword: false,
    },
  })
  if (response) {
    const { payment } = response.data.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        data: payment,
        displayConvergePinModal: false,
      },
    })
  }
}

export function* UPDATE_CONVERGE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updatingConverge: true,
    },
  })
  const { response } = yield call(updateConverge, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updatingConverge: false,
    },
  })
  if (response) {
    const { payment } = response.data.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        data: payment,
        displayConvergePaymentDrawer: false,
      },
    })
  }
}

export function* AUTH_CLOVER() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      connectingClover: true,
    },
  })
  const { response } = yield call(authClover)
  if (response) {
    const { redirect_url } = response.data.data
    window.location = redirect_url
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        connectingClover: false,
      },
    })
  }
}

export function* UPDATE_CLOVER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updatingClover: true,
    },
  })
  const { response } = yield call(updateClover, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updatingClover: false,
    },
  })
  if (response) {
    const { payment } = response.data.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        data: payment,
        displayCloverPaymentDrawer: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD, LOAD),
    takeLatest(actions.CONVERGE_PIN, CONVERGE_PIN),
    takeLatest(actions.UPDATE_CONVERGE, UPDATE_CONVERGE),
    takeLatest(actions.AUTH_CLOVER, AUTH_CLOVER),
    takeLatest(actions.UPDATE_CLOVER, UPDATE_CLOVER),
  ])
}
