import actions from './actions'

const initialState = {
  phyFirstArray: [],
  phySecondArray: [],
  couponResData: null,
  giftCardApiStatus: null,
  couponResStatus: null,
  cardTypeValue: null,
  couponCode: null,
  giftIssued: null,
  apiCallCount: false,
  cardIssued: false,
  secondTime: false,
  loadApiResult: false,
  image: null,
  is_valid_gift_card: null,
  giftRedeemed: null,
  giftUnredeemed: null,
  data: null,
  search: null,
  GiftCardId: null,
  reservedGiftCard: null,
  reservedGiftCard2: null,
  paymentMode: null,
  transactions: null,
  transactionTypes: null,
  salesSummary: {
    summary: null,
    transactions: null,
    search: null,
  },
  redeemedSummary: {
    summary: null,
    transactions: null,
    search: null,
  },
  selectedData: null,
  loadingDigitalCard: false,
  issuing: false,
  reloading: false,
  couponAdding: false,
  resending: false,
  redeeming: false,
  importing: false,
  loadingSalesSummary: false,
  loadingRedeemedSummary: false,
  displayIssueDrawer: false,
  displayTransactionHistoryDrawer: false,
  displayReloadDrawer: false,
  displayResendDrawer: false,
  displayRedeemDrawer: false,
  displayCustomRedeemDrawer: false,
  displayUpdateDrawer: false,
  displayChooseImageModal: false,
  newlyUploaded: false,
  loadedCoupon: false,
}

export default function giftCardReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_SALES_SUMMARY_SEARCH:
      return { ...state, salesReport: { ...state.salesSummary, search: action.payload } }
    case actions.SET_REDEEMED_SUMMARY_SEARCH:
      return { ...state, redeemedSummary: { ...state.redeemedSummary, search: action.payload } }
    case actions.EDIT_DATA:
      return { ...state, displayRedeemDrawer: false }
    case actions.UPDATE_IMAGE:
      return {
        ...state,
        data: {
          ...state.data,
          gift_card: {
            ...state.data.gift_card,
            strip_image: action.payload.image.image,
            strip_image_url: action.payload.image.image_url,
            custom_images: [...state.data.gift_card.custom_images, action.payload.image],
          },
        },
        displayChooseImageModal: false,
      }
    default:
      return state
  }
}
