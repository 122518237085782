import { all } from 'redux-saga/effects'
import businessType from './business-type/sagas'
import country from './country/sagas'
import user from './user/sagas'
import menu from './menu/sagas'
import business from './business/sagas'
import location from './location/sagas'
import payment from './payment/sagas'
import giftCard from './gift-card/sagas'
import customer from './customer/sagas'
import setting from './setting/sagas'
import staff from './staff/sagas'
import coupon from './coupon/sagas'
import settings from './settings/sagas'
import subscription from './subscription/sagas'
import invoice from './invoice/sagas'
import emailMarketing from './email-marketing/sagas'

export default function* rootSaga() {
  yield all([
    businessType(),
    country(),
    user(),
    menu(),
    business(),
    location(),
    payment(),
    giftCard(),
    customer(),
    setting(),
    staff(),
    coupon(),
    settings(),
    subscription(),
    invoice(),
    emailMarketing(),
  ])
}
