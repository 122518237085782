import apiClient from 'services/axios'

const BASE_URL = 'user'

export async function load(payload) {
  return apiClient
    .get(`${BASE_URL}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function register(payload) {
  return apiClient
    .post(`${BASE_URL}/register`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function changeEmail(payload) {
  return apiClient
    .post(`${BASE_URL}/email/change`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function resendEmail(payload) {
  return apiClient
    .post(`${BASE_URL}/email/resend`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function confirmEmail(payload) {
  return apiClient
    .post(`${BASE_URL}/email/confirm`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function login(payload) {
  return apiClient
    .post(`${BASE_URL}/login`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function loginSessionVerify(payload) {
  return apiClient
    .post(`${BASE_URL}/login-verify`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function recoverPassword(payload) {
  return apiClient
    .post(`${BASE_URL}/password/recover`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function resetPassword(payload) {
  return apiClient
    .post(`${BASE_URL}/password/reset`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function logout() {
  return apiClient
    .delete(`${BASE_URL}/logout`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
