import actions from './actions'

const initialState = {
  data: null,
  images: null,

  loading: false,
  confirmingPassword: false,
  updatingConverge: false,
  connectingClover: false,
  updatingClover: false,

  displayConvergePaymentDrawer: false,
  displayConvergePinModal: false,
  displayCloverPaymentDrawer: false,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
