/* eslint-disable camelcase */
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { load, loadStore, update, updateStore, uploadImage } from 'api/setting'
import actions from './actions'

export function* LOAD() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      data: null,
    },
  })
  const { response } = yield call(load)
  if (response) {
    const {
      setting,
      text_colors,
      gift_card_images,
      digital_gift_card,
      physical_gift_card,
      import_template_url,
    } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        data: setting,
        textColors: text_colors,
        defaultImages: gift_card_images,
        digitalGiftCard: digital_gift_card,
        physicalGiftCard: physical_gift_card,
        importTemplateUrl: import_template_url,
      },
    })
  }
}

export function* LOAD_STORE() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(loadStore)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  if (response) {
    const { setting, business, location } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        data: setting,
        business,
        location,
      },
    })
  }
}

export function* UPDATE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  yield call(update, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
}

export function* UPDATE_STORE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  yield call(updateStore, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
}

export function* UPLOAD_IMAGE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      uploading: true,
    },
  })
  const { response } = yield call(uploadImage, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      uploading: false,
    },
  })
  if (response) {
    yield put({
      type: actions.UPDATE_IMAGE,
      payload: {
        image: response.data.image,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD, LOAD),
    takeLatest(actions.LOAD_STORE, LOAD_STORE),
    takeLatest(actions.UPDATE, UPDATE),
    takeLatest(actions.UPDATE_STORE, UPDATE_STORE),
    takeLatest(actions.UPLOAD_IMAGE, UPLOAD_IMAGE),
  ])
}
