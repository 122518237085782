import actions from './actions'

const initialState = {
  data: null,
  inventory: null,
  billing: null,
  loading: false,

  creating: false,

  displayAddCardModal: false,
  addingCard: false,

  displayChangeCardDrawer: false,
  changingDefaultCard: false,
  removingCard: false,

  displayPurchaseCardDrawer: false,
  purchasingGiftCard: false,
}

export default function subscriptionReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
