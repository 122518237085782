import actions from './actions'

const initialState = {
  data: null,
  selectedData: null,

  search: '',

  pagelimit: 10,
  pagesize: 1,

  displayUpdateDrawer: false,
  updating: false,

  pageLoader: false,

  errors: {
    update: null,
  },
}

export default function customerReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_ERROR_STATE:
      return { ...state, errors: { ...state.errors, ...action.payload } }
    case actions.EDIT_DATA:
      return {
        ...state,
        displayUpdateDrawer: false,
        data: state.data.map(o => {
          if (action.payload.data._id === o._id) {
            return action.payload.data
          }
          return o
        }),
      }
    case actions.SHOW_UPDATE:
      return { ...state, selectedData: action.payload.customer, displayUpdateDrawer: true }
    case actions.HIDE_UPDATE:
      return { ...state, selectedData: null, displayUpdateDrawer: false }
    default:
      return state
  }
}
