export default async function getMenuData() {
  return [
    {
      category: true,
      title: 'Manage',
      key: 'manage',
    },
    {
      title: 'Manage Gift Cards',
      key: 'manage-gift-cards',
      icon: 'fe fe-gift',
      url: '/manage/gift-card',
    },
    {
      title: 'Manage Customers',
      key: 'manage-customers',
      icon: 'fe fe-users',
      url: '/manage/customer',
    },
    {
      title: 'Manage Users',
      key: 'manage-users',
      icon: 'fe fe-user-check',
      url: '/manage/user',
    },
    {
      title: 'Manage Coupons',
      key: 'manage-coupons',
      icon: 'fe fe-tag',
      url: '/manage/coupon',
    },
    {
      category: true,
      title: 'Reports',
      key: 'reports',
    },
    {
      title: 'Sales Summary',
      key: 'sale-summary',
      icon: 'fe fe-file-text',
      url: '/summary/sale',
    },
    {
      title: 'Redeemed Summary',
      key: 'redeemed-summary',
      icon: 'fe fe-file-text',
      url: '/summary/redeem',
    },
    {
      category: true,
      title: 'Setup',
      key: 'setup',
    },
    {
      title: 'Store Setup',
      key: 'store-setup',
      icon: 'fe fe-shopping-bag',
      url: '/setup/store',
    },
    {
      title: 'Gift Card Setup',
      key: 'gift-card-setup',
      icon: 'fa fa-edit',
      url: '/setup/gift-card',
    },
    {
      title: 'Integrations',
      key: 'integrations',
      icon: 'fe fe-settings',
      url: '/setup/integration',
    },
    {
      category: true,
      title: 'Billing',
      key: 'billing',
    },
    {
      title: 'Plan and Inventory',
      key: 'plan-payments',
      icon: 'fe fe-credit-card',
      url: '/billing/plan-inventory',
    },
    {
      title: 'Payment History',
      key: 'payment-history',
      icon: 'fa fa-history',
      url: '/billing/payment-history',
    },
    {
      category: true,
      title: 'Email Marketing',
      key: 'email-marketing',
    },
    {
      title: 'Create Email',
      key: 'create-email',
      icon: 'fe fe-credit-card',
      url: '/email-marketing/create-email',
    },
    {
      title: 'Drafts',
      key: 'drafts',
      icon: 'fa fa-history',
      url: '/email-marketing/drafts',
    },
    {
      title: 'Email History',
      key: 'email-history',
      icon: 'fa fa-history',
      url: '/email-marketing/email-history',
    },
  ]
}
