const actions = {
  SET_STATE: 'email-marketing/SET_STATE',
  ADD_DATA: 'email-marketing/ADD_DATA',
  LOAD_ALL: 'email-marketing/LOAD_ALL',

  GET_CUSTOMERS: 'customer/GET_CUSTOMERS',
  GET_CUSTOMERS_LIST: 'email-marketing/GET_CUSTOMERS_LIST',

  CREATE_EMAIL: 'email-marketing/CREATE_EMAIL',

  DRAFT_EMAIL: 'email-marketing/DRAFT_EMAIL',
  GET_DRAFT_EMAIL: 'email-marketing/GET_DRAFT_EMAIL',

  GET_EMAIL_HISTORY: 'email-marketing/GET_EMAIL_HISTORY',

  EDIT_EMAIL: 'email-marketing/EDIT_EMAIL',
  SHOW_UPDATE_EMAIL: 'email-marketing/SHOW_UPDATE_EMAIL',
  HIDE_UPDATE_EMAIL: 'email-marketing/HIDE_UPDATE_EMAIL',

  REMOVE_EMAIL: 'email-marketing/REMOVE_EMAIL',
  SHOW_REMOVE_EMAIL: 'email-marketing/SHOW_REMOVE_EMAIL',
  HIDE_REMOVE_EMAIL: 'email-marketing/HIDE_REMOVE_EMAIL',

  UPDATE_EMAIL: 'email-marketing/UPDATE_EMAIL',
  REMOVE: 'email-marketing/REMOVE',
}

export default actions
