const actions = {
  SET_STATE: 'location/SET_STATE',
  SET_ERROR_STATE: 'location/SET_ERROR_STATE',
  EDIT_DATA: 'location/EDIT_DATA',
  REMOVE_DATA: 'location/REMOVE_DATA',
  SHOW_CREATE: 'location/SHOW_CREATE',
  HIDE_CREATE: 'location/HIDE_CREATE',
  SHOW_UPDATE: 'location/SHOW_UPDATE',
  HIDE_UPDATE: 'location/HIDE_UPDATE',

  LOAD_ALL: 'location/LOAD_ALL',
  CREATE: 'location/CREATE',
  UPDATE: 'location/UPDATE',
}

export default actions
