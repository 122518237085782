import { all, call, put, takeLatest } from 'redux-saga/effects'
import loadAll from 'api/business-type'
import actions from './actions'

export function* LOAD_ALL() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      data: null,
      loading: true,
    },
  })
  const { response } = yield call(loadAll)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  if (response) {
    const { business_types } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        data: business_types,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.LOAD_ALL, LOAD_ALL)])
}
