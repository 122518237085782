import apiClient from 'services/axios'

const BASE_URL = 'subscription'

export async function loadPlanInventory() {
  return apiClient
    .get(`${BASE_URL}/plan-inventory`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function create(payload) {
  return apiClient
    .post(`${BASE_URL}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function addCard(payload) {
  return apiClient
    .post(`${BASE_URL}/card`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function changeDefaultCard(id) {
  return apiClient
    .patch(`${BASE_URL}/card/${id}/default`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function removeCard(id) {
  return apiClient
    .delete(`${BASE_URL}/card/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function purchaseGiftCard(payload) {
  return apiClient
    .post(`${BASE_URL}/purchase-gift-card`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
