import localeAntd from 'antd/es/locale/en_US'

const messages = {
  'action.addCard': 'Add New Card',
  'action.addCardSubscribe': 'Add Card & Subscribe',
  'action.addCoupon': 'Add Coupon',
  'action.addCustomImage': 'Add Custom Image',
  'action.addLocation': 'Add Location',
  'action.addUser': 'Add User',
  'action.apply': 'Apply',
  'action.applyCoupon': 'Apply Coupon',
  'action.cancel': 'Cancel',
  'action.change': 'Change',
  'action.changeColor': 'Change Color',
  'action.choose': 'Choose',
  'action.chooseImage': 'Choose an Image',
  'action.confirm': 'Confirm',
  'action.connect': 'Connect',
  'action.copyStoreUrl': 'Copy Store URL',
  'action.customerPortalBgImg': 'Customer Portal Background Image',
  'action.downloadTemplate': 'Download Template',
  'action.edit': 'Edit',
  'action.modify': 'Modify',
  'action.export': 'Export',
  'action.forgotPassword': 'Forgot password?',
  'action.giftCardPromotion': 'Gift Card Promotion',
  'action.gotoSignIn': 'Go to Sign in',
  'action.import': 'Import',
  'action.issue': 'Issue',
  'action.issueGiftCard': 'Issue Gift Card',
  'action.logout': 'Logout',
  'action.next': 'Next',
  'action.manage': 'Manage',
  'action.no': 'No',
  'action.purchase': 'Purchase',
  'action.purchaseGiftCards': 'Purchase Gift Cards',
  'action.purchaseGiftCardNumbers': 'Purchase Gift Card Numbers',
  'action.recoverPassword': 'Recover my password',
  'action.redeem': 'Redeem',
  'action.customRedeem': 'Redeem',
  'action.reload': 'Reload',
  'action.remove': 'Remove',
  'action.removeCoupon': 'Remove Coupon',
  'action.resend': 'Resend',

  'action.resendEmail': 'Resend Email',
  'action.resetPassword': 'Reset my password',
  'action.save': 'Save',
  'action.search': 'Search',
  'action.select': 'Select',
  'action.sendemail': 'Send Email',
  'action.savedraft': 'Save Draft',
  'action.discardmail': 'Discard Mail',
  'action.setDefault': 'Set Default',
  'action.send': 'Send',
  'action.signIn': 'Sign in',
  'action.signUp': 'Sign up',
  'action.update': 'Update',
  'action.view': 'View',
  'action.viewDetails': 'View Details',
  'action.viewReceipt': 'View Receipt',
  'action.viewStore': 'View Store',
  'action.yes': 'Yes',
  'action.purchaseMoreCards': 'Purchase More Cards',
  'text.GiftCardImage': 'Gift Card Image',

  'desc.backgroundImage': 'Background Image',
  'desc.barcode': 'Barcode',
  'desc.icon': 'Icon',

  'text.Coupons': 'Coupon',
  'text.Discount': 'Discount',
  'text.Promotions': 'Promotion',
  'form.label.coupon.options': 'Option',
  'text.option': 'Option',

  'empty.coupon': 'No coupons found',
  'empty.customer': 'No customers found',
  'empty.giftCard': 'No gift cards found',
  'empty.image': 'No images',
  'empty.invoice': 'No invoices found',
  'empty.paymentHistory': 'No payment history found',
  'empty.redeemedSummary': 'No gift card redeemed summary found',
  'empty.saleSummary': 'No gift card sales summary found',
  'empty.staff': 'No users found',
  'empty.emailDraft': 'No Data in Email Draft',
  'empty.emailHistory': 'No Email Send Yet',

  'error.subscription': 'You do not have an active subscription. Please subscribe to proceed.',
  'error.giftCard.digital.issue':
    'You do not have enough digital card to issue. Please purchase more gift card numbers.',
  'error.giftCard.export':
    'You do not have any unused physical cards. Please purchase more gift card numbers.',
  'error.giftCard.import':
    'You have to purchase gift card numbers to import third party gift cards numbers.',

  'form.check.makeDefaultPaymentMethod': 'Make this my default payment method',

  'form.error.agentId.len': 'Agent ID must be 6 digits',
  'form.error.amount.required': 'Please input the amount',
  'form.error.amount.min': 'Must be min. $1.00',
  'form.error.amount.minforstore': 'Must be min. $1.00',
  'form.error.amount.addmoreerror': ' Maxstore balance amount {amount}',
  'form.error.minamount': '{minpurchaseamountval}',
  'form.error.minimumamount': 'Must be min. $1.00 or more',
  'form.error.minimumamountforpercard': 'Must be min. $1.00 or more',
  'form.error.minimumamountforstore': 'Must be min. $1.00 or more',
  'form.error.minimumamountpercard': 'Must be max {amount} percard',
  'form.error.storeamountlimit': 'Balance store Limit {amount} ',
  'form.error.minimumamountrequired': 'Required',
  'form.error.businessAddress.required': 'Please input the business address',
  'form.error.businessAddress.max': 'Business address must be up to 180 characters',
  'form.error.businessName.required': 'Please input the business name',
  'form.error.businessName.max': 'Business name must be up to 64 characters',
  'form.error.code.max': 'Code must be up to 20 characters',
  'form.error.code.min': 'Code must be min 3 characters',
  'form.error.contactPhone.invalid': 'Invalid contact phone number',
  'form.error.contactPhone.alt.invalid': 'Invalid alt. contact phone number',
  'form.error.coupon.minPurchaseValue': 'Min. Purchase Value cannot be less than the Value',
  'form.error.coupon.flatvalue': 'Flat Rate cannot be less than zero or in negative value',
  'form.error.coupon.percentage': 'Percentage cannot be less than zero or in negative',
  'form.error.email.required': 'Please input the email address',
  'form.error.email.type': 'Invalid email address',
  'form.error.email.max': 'Email address must be up to 64 characters',
  'form.error.empty.select': 'No matches found.',
  'form.error.firstName.required': 'Please input the first name',
  'form.error.firstName.max': 'First name must be up to 64 characters',
  'form.error.giftCardBalance.required': 'Please input the gift card balance',
  'form.error.giftCardNumber.required': 'Please enter Gift Card Number',
  'form.error.giftCardAmount.required': 'Please enter Gift Card Balance',
  'form.error.giftCardNumberRequired': 'Please enter gift card number',
  'form.error.giftCardPurchase.min': 'Min. 100 cards has to be purchased',
  'form.error.invalid': 'Invalid',
  'form.error.lastName.required': 'Please input the last name',
  'form.error.lastName.max': 'Last name must be up to 64 characters',
  'form.error.locationAddress.required': 'Please input the location address',
  'form.error.locationAddress.max': 'Business address must be up to 180 characters',
  'form.error.loginPin.length': 'Must be a 4 digit pin',
  'form.error.password.required': 'Please input the password',
  'form.error.password.min': 'Password must be at least 8 characters',
  'form.error.password.max': 'Password must be up to 32 characters',
  'form.error.password.match': "Passwords doesn't match",
  'form.error.phone.invalid': 'Invalid phone number',
  'form.error.reloadValue.required': 'Please input the reload value',
  'form.error.required': 'Required',
  'form.error.disallow.specialChars': 'Special characters are not allowed',

  'form.helper.coupon.channel': 'Valid only at the selected channels',
  'form.helper.coupon.purchaseType': 'Valid only for the selected purchase types',
  'form.helper.recipientEmail': 'Digital card will be sent to this email',
  'form.helper.senderEmail': 'A copy of the digital card will be sent to this email',
  'form.helper.billing.digitalCards':
    'Digital cards will be sent to the customer by email and they can add your gift card to through Apple Wallet or Android Wallet. These cards provide realtime balance updates to your customers.',
  'form.helper.billing.physicalCards':
    'Physical cards list can be downloaded from the gift card setup page and printed at your preferred card printing service provider or you can view the list of recommended gift card printing service providers in your country on the gift card setup page.',
  'form.helper.giftCard.issue': 'You are issuing a gift card as {user}',
  'form.helper.giftCard.redeem': 'You are redeeming a gift card as {user}',
  'form.helper.giftCard.reload': 'You are reloading a gift card as {user}',

  'action.proceedCheckout': 'Proceed Checkout',
  'action.Edit': 'Edit',
  'form.label.active': 'Active',
  'form.label.address': 'Address',
  'form.label.agentId': 'Agent ID',
  'form.label.allowCashSales': 'Allow Cash Sales?',
  'form.label.allowIssueCard': 'Allow to Issue Card?',
  'form.label.allowManageCustomers': 'Allow to Manage Customers?',
  'form.label.allowManageCustomers.all': 'Allow to Manage All Customers?',
  'form.label.allowManageGiftCards': 'Allow to Manage All Gift Cards?',
  'form.label.allowManageUsers': 'Allow to Manage Users?',
  'form.label.allowRedeemCard': 'Allow to Redeem Card?',
  'form.label.allowReloadCard': 'Allow to Reload Card?',
  'form.label.allowRunReport': 'Allow to Run Reports?',
  'form.label.altPhone': 'Alt. Phone Number',
  'form.label.appId': 'App ID',
  'form.label.threeDigitFin': '3 Digit Financial Instituition Number',
  'form.label.fiveDigitFin': '5 Digit Financial Instituition Number',
  'form.label.tweleDigitFin': '5-12 Digit Financial Instituition Number',
  'form.label.recipientName': 'Recipient Name',
  'form.label.customerCode': 'Customer Code',
  'form.label.backgroundColor': 'Background Color',
  'form.label.backgroundImage': 'Background Image',
  'form.label.businessAddress': 'Business Address',
  'form.label.businessName': 'Business Name',
  'form.label.businessType': 'Business Type',
  'form.label.channel': 'Channel',
  'form.label.email': 'Email',
  'form.label.email.current': 'Current Email',
  'form.label.email.new': 'New Email',
  'form.label.from': 'From',
  'form.label.cardDetails': 'Card Details',
  'form.label.cardNumber': 'Card Number',
  'form.label.city': 'City',
  'form.label.code': 'Code',
  'form.label.contactName': 'Contact Name',
  'form.label.contactPhone': 'Contact Phone Number',
  'form.label.contactPhone.alt': 'Alt. Phone Number',
  'form.label.coupon.code': 'Code',
  'form.label.coupon.minPurchaseValue': 'Min. Purchase Value',
  'form.label.coupon.value': 'Value',
  'form.label.country': 'Country',
  'form.label.currency': 'Currency',
  'form.label.cvv': 'CVV',
  'form.label.description': 'Description',
  'form.label.deliveryDate': 'Delivery Date',
  'form.label.employeeId': 'Employee ID',
  'form.label.expiry': 'Expiry Date',
  'form.label.freePhysicalCards': 'Did you receive our 10 free physical gift cards?',
  'form.label.firstName': 'First Name',
  'form.label.giftCardBalance': 'Gift Card Balance',
  'form.label.giftCardCopy': 'Send a copy of Gift Card?',
  'form.label.onlinereceipt': 'Send a receipt to merchant?',
  'form.label.editstorelimit': 'Enable perday store limit?',
  'text.OnlineStoreNotifications': 'Online Store Notifications',
  'text.GiftcardLimitSettings': 'Manage Online Store Transactions',
  'form.label.giftCardNumber': 'Gift Card Number',
  'form.label.giftCardQuantity': 'Enter the number of gift cards you would like to purchase',
  'form.label.giftCardType': 'Gift Card Type',
  'form.label.image': 'Image',
  'form.label.language': 'Language',
  'form.label.language.preferred': 'Preferred Language',
  'form.label.lastName': 'Last Name',
  'form.label.location': 'Location',
  'form.label.locationAddress': 'Location Address',
  'form.label.loginPin': 'Login Pin',
  'form.label.merchantId': 'Merchant ID',
  'form.label.message': 'Message',
  'form.label.minPurchaseValue': 'Min. Purchase Value',
  'form.label.name': 'Name',
  'form.label.password': 'Password',
  'form.label.password.new': 'New Password',
  'form.label.password.confirm': 'Confirm Password',
  'form.label.payBy': 'Pay by',
  'form.label.payment': 'Payment',
  'form.label.paymentMethod': 'Payment Method',
  'form.label.subject': 'Subject',
  'form.label.emailbody': 'Email Body',
  'form.label.payWith': 'Pay with',
  'form.label.phone': 'Phone Number',
  'form.label.pin': 'Pin',
  'form.label.postalCode': 'Zip/Postal Code',
  'form.label.province': 'State/Province',
  'form.label.purchaseType': 'Purchase Type',
  'form.label.redeemValue': 'Redeem Value',
  'form.label.rePassword': 'Re-Password',
  'form.label.reloadValue': 'Reload Value',
  'form.label.retypeLoginPin': 'Retype Login Pin',
  'form.label.role': 'Role',
  'form.label.storeName': 'Store Name',
  'form.label.storeType': 'Store Type',
  'form.label.textColor': 'Text Color',
  'form.label.themeColor': 'Theme Color',
  'form.label.timezone': 'Timezone',
  'form.label.to': 'To',
  'form.label.transactionType': 'Transaction Type',
  'form.label.user': 'User',
  'form.label.userId': 'User ID',
  'form.label.zipPostalCode': 'Zip/Postal Code',
  'form.label.issuecardlimit': 'Number Of Card',
  'form.label.percardlimit': 'Per Card Limit',
  'form.label.perdaystorelimit': 'Perday Store Limit',

  'form.placeholder.address': 'Enter the address',
  'form.placeholder.agentId': 'Enter the agent id',
  'form.placeholder.altPhone': 'Enter the alt. phone number',
  'form.placeholder.businessCity': 'City',
  'form.placeholder.businessCountry': 'Country',
  'form.placeholder.businessName': 'Enter the business name',
  'form.placeholder.businessPostalCode': 'Zip/Postal code',
  'form.placeholder.businessProvince': 'State/Province',
  'form.placeholder.businessType': 'Select the business type',
  'form.placeholder.amount': '0.00',
  'form.placeholder.cardName': 'Name on card',
  'form.placeholder.cardNumber': '4242 4242 4242 4242',
  'form.placeholder.city': 'Select the city',
  'form.placeholder.contactName': 'Enter the contact name',
  'form.placeholder.contactPhone': 'Enter the contact phone number',
  'form.placeholder.contactPhone.alt': 'Enter the alt. phone number',
  'form.placeholder.coupon.code': 'Code',
  'form.placeholder.coupon.description': 'Short description about the promotion / coupon',
  'form.placeholder.coupon.value': 'Value',
  'form.placeholder.country': 'Select the country',
  'form.placeholder.currency': 'Select the currency',
  'form.placeholder.customerEmail': 'Enter the customer email',
  'form.placeholder.customerName': 'Enter the customer name',
  'form.placeholder.customerPhone': 'Enter the customer phone number',
  'form.placeholder.email': 'Enter the email address',
  'form.placeholder.email.current': 'Enter the current email address',
  'form.placeholder.email.new': 'Enter the new email address',
  'form.placeholder.expirationMonth': 'MM',
  'form.placeholder.expirationYear': 'YY',
  'form.placeholder.firstName': 'Enter the first name',
  'form.placeholder.subject': 'Please Enter Subject Details...',
  'form.placeholder.giftCardBalance': 'Enter the gift card balance',
  'form.placeholder.giftCardNumber': 'xxxxxxxxxxxxxxxx',
  'form.placeholder.language': 'Select the language',
  'form.placeholder.lastName': 'Enter the last name',
  'form.placeholder.location.name': 'Enter the location name',
  'form.placeholder.locations': 'Select the locations',
  'form.placeholder.loginPin': 'Enter the 4-digit login pin',
  'form.placeholder.message': 'Enter a personalised message',
  'form.placeholder.name': 'Full Name',
  'form.placeholder.recipientEmail': "Enter the recipient's email",
  'form.placeholder.recipientName': "Enter the recipient's full name",
  'form.placeholder.recipientPhone': "Enter the recipient's phone number",
  'form.placeholder.password': 'Enter the password',
  'form.placeholder.password.account': 'Your account password',
  'form.placeholder.password.confirm': 'Enter the new password again',
  'form.placeholder.password.new': 'Enter the new password',
  'form.placeholder.phone': 'Enter the phone number',
  'form.placeholder.postalCode': 'Zip/Postal code',
  'form.placeholder.province': 'Select the state/province',
  'form.placeholder.qty': '10000',
  'form.placeholder.rePassword': 'Enter the password again',
  'form.placeholder.retypeLoginPin': 'Enter the 4-digit login pin again',
  'form.placeholder.role': 'Select the role',
  'form.placeholder.search': 'Search..',
  'form.placeholder.search.customer': 'Search by name or email..',
  'form.placeholder.search.giftCard': 'Search by name or card number..',
  'form.placeholder.search.invoice': 'Search by invoice number..',
  'form.placeholder.search.staff': 'Search by name or email..',
  'form.placeholder.senderEmail': "Enter the sender's email",
  'form.placeholder.senderName': "Enter the sender's full name",
  'form.placeholder.senderPhone': "Enter the sender's phone number",
  'form.placeholder.storeName': 'Enter the store name',
  'form.placeholder.storeType': 'Enter the store type',
  'form.placeholder.streetAddress': 'Street address',
  'form.placeholder.colorCode': 'Enter Color Code',
  'form.placeholder.timezone': 'Select the timezone',
  'form.placeholder.transactionType': 'Select the timezone',
  'form.placeholder.users': 'Select the users for this location',
  'form.placeholder.search.recipient': 'Search Recipient',
  'form.placeholder.cardcountperday': 'Please enter the card count per day',
  'form.placeholder.percardamtlimit': 'Please enter the max amount per card',
  'form.placeholder.perdaystorelimit': 'Please enter the max amount for store per day',

  'form.select.all': 'All',
  'form.select.card': 'Card',
  'form.select.cash': 'Cash',
  'form.select.english': 'English',
  'form.select.french': 'French',
  'form.select.inStore': 'In-Store',
  'form.select.onlineStore': 'Online Store',
  'form.select.purchase': 'Purchase',
  'form.select.redeem': 'Redeem',
  'form.select.reload': 'Reload',

  'form.suffix.qty': 'Qty',

  'helper.dragDropImage': 'Click or drag image to this area to upload',

  // common links
  'link.termsService': 'Terms of Service',
  'link.privacyPolicy': 'Privacy Policy',

  'message.copyClipboard': 'Copied to clipboard',

  'note.logoSize':
    'Image dimension should be 230px × 87px in JPG or PNG format with the size less than 1MB.',

  'progress.uploading': 'Uploading.. please wait..',
  'progress.verifyingCode': 'Verifying the code..',

  'tab.giftEasyGallery': 'Gift Easy Gallery',
  'tab.upload': 'Upload',
  'tab.yourLibrary': 'Your Library',

  'text.action': 'Action',
  'text.active': 'Active',
  'text.activeSince': 'Active Since',
  'text.activity': 'Activity',
  'text.addCoupon': 'Add Coupon',
  'text.addLocation': 'Add Location',
  'text.addNewCard': 'Add New Card',
  'text.addUser': 'Add User',
  'text.addedOn': 'Added on {date}',
  'text.address': 'Address',
  'text.applyCoupon': 'Apply Coupon',
  'text.applyLocations': 'Apply to Locations',
  'text.assignLocations': 'Assign Locations',
  'text.alreadyHaveAccount': 'Already have an account?',
  'text.and.spaced': ' and ',
  'text.amount': 'Amount',
  'text.amountPay': 'Amount to Pay',
  'text.authNumber': 'Auth. Number',
  'text.available': 'Available',
  'text.billTo': 'Bill To',
  'text.businessProfile.title': 'Business Profile',
  'text.businessProfile.description':
    'Tell us about your business so we can help you set it up to accept payments.',
  'text.byUser': 'By {name} - {location}',
  'text.card': 'Card',
  'text.cardDetails': 'Card Details',
  'text.cash': 'Cash',
  'text.channel': 'Channel',
  'text.changeDefaultCard.content': 'Are you sure you want to change the default card?',
  'text.changeEmail': 'Change Email',
  'text.changeCard': 'Change Card',
  'text.chooseImage': 'Choose Image',
  'text.city': 'City',
  'text.clover': 'Clover',
  'text.bambora': 'Bambora',
  'text.cloverPaymentInfo': 'Clover Payment Info',
  'text.bamboraPaymentInfo': 'Bambora Payment Info',
  'text.code': 'Code',
  'text.confirmEmail.title': 'Confirm Email',
  'text.confirmEmail.description':
    "We've sent a 6-digit verification code to {email}.{break}The code expires in 10m, so please enter it soon.",
  'text.confirmPassword': 'Confirm Password',
  'text.contactDetails': 'Contact Details',
  'text.converge': 'Converge',
  'text.convergePaymentInfo': 'Converge Payment Info',
  'text.copyright': 'Copyright © {copyrighttext}. All rights reserved.',
  'text.copyrightInnerpage': 'Copyright © {copyrighttext}.',
  'text.couponApplied': 'Coupon Applied',
  'text.couponDetails': 'Coupon Details',
  'text.couponList': 'Coupons List',
  'text.createAccount': 'Create your account',
  'text.creditCard': 'Credit Card',
  'text.currentPlan': 'Current Plan',
  'text.customerEmail': 'Customer Email',
  'text.customerList': 'Customer List',
  'text.customerName': 'Customer Name',
  'text.customerPhone': 'Customer Phone',
  'text.date': 'Date',
  'text.dateTime': 'Date & Time',
  'text.default': 'Default',
  'text.description': 'Description',
  'text.didntReceiveCode': "Didn't receive the code?",
  'text.digitalCard': 'Digital Card',
  'text.digitalCards': 'Digital Cards',
  'text.discount': 'Discount',
  'text.discountApplied': 'Discount Applied',
  'text.promoApplied': 'Promo Applied',
  'text.dontHaveAccount': "Don't have an account?",
  'text.dueDate': 'Due Date',
  'text.editCustomer': 'Edit Customer',
  'text.email': 'Email',
  'text.expiresOn': 'Expires {month}/{year}',
  'text.expiry': 'Expiry Date',
  'text.exportPhysicalCards': 'Export Physical Cards',
  'text.features': 'Features',
  'text.giftCardBalance': 'Gift Card Balance',
  'text.giftCardDesign': 'Gift Card Design',
  'text.giftCardDetails': 'Gift Card Details',
  'text.giftCardInventory': 'Gift Card Inventory',
  'text.giftCardList': 'Gift Card List',
  'text.giftCardNumber': 'Gift Card Number',
  'text.giftCardReport': 'Gift Card Report',
  'text.giftCardStoreDesign': 'Gift Card Store Design',
  'text.giftCardValue': 'Gift Card Value',
  'text.giftIssued': 'Gift Issued',
  'text.giftRedeemed': 'Gift Redeemed',
  'text.giftUnredeemed': 'Gift Unredeemed',
  'text.importExternalCards.title': 'Import External Gift Cards',
  'text.importExternalCards.description':
    'Download the excel template from the link below and upload the same for importing the gift cards from other platforms.',
  'text.inactive': 'Inactive',
  'text.incl.tax': 'incl. tax',
  'text.invoiceAmount': 'Invoice Amount',
  'text.invoiceDate': 'Invoice Date',
  'text.invoiceNo': 'Invoice No.',
  'text.invoice': 'Invoice',
  'text.invoices': 'Invoices',
  'text.subject': 'Subject',
  'text.updated': 'Updated',
  'text.sendOn': 'Send On',
  'text.emailstatus': 'Status',
  'text.sendto': 'Send To',
  'text.draftaction': 'Action',
  'text.issued': 'Issued',
  'text.issueGiftCard': 'Issue Gift Card',
  'text.lastPurchasedAt': 'Last Purchased At',
  'text.location': 'Location',
  'text.locations': 'Locations',
  'text.locationDetails': 'Location Details',
  'text.locationList': 'Location List',
  'text.logo': 'Logo',
  'text.logout.content': 'Are you sure you want to logout?',
  'text.month': 'month',
  'text.name': 'Name',
  'text.nextBillingDate': 'Next Billing Date',
  'text.notConnected': 'Not Connected',
  'text.notPaid': 'Not Paid',
  'text.onlineStore': 'Online Store',
  'text.ownerProfile.title': 'Owner Profile',
  'text.ownerProfile.description':
    'Tell us about the owner of the business to start your application.',
  'text.paid': 'Paid',
  'text.paidAmount': 'Paid Amount',
  'text.paidDate': 'Paid Date',
  'text.payment.clover.change.title': 'Change Account',
  'text.payment.clover.change.description': 'Connect with a different clover merchant account.',
  'text.paymentAccountInfo': 'Payment Account Info',
  'text.paymentDate': 'Payment Date',
  'text.paymentDetails': 'Payment Details',
  'text.paymentMethod': 'Payment Method',
  'text.paymentStatus': 'Payment Status',
  'text.permissions': 'Permissions',
  'text.permissions.location': 'Location Permissions',
  'text.physicalCard': 'Physical Card',
  'text.physicalCards': 'Physical Cards',
  'text.physicalCardDetails':
    'You have purchased {totalCount} physical gift cards and there are {unusedCount} unused cards.',
  'text.plan': 'Plan',
  'text.planDetails': 'Plan Details',
  'text.postalCode': 'Zip/Postal Code',
  'text.preferences': 'Preferences',
  'text.preview.digitalCard': 'Digital Card Preview',
  'text.purchaseAmount': 'Purchase Amount',
  'text.purchaseAmount.total': 'Total Purchase Amount',
  'text.purchaseGiftCardNumbers': 'Purchase Gift Card Numbers',
  'text.quantity': 'Quantity',
  'text.recipientDetails': 'Recipient Details',
  'text.recipientName': 'Recipient Name',
  'text.redeemedAmount': 'Redeemed Amount',
  'text.redeemGiftCard': 'Redeem Gift Card',
  'text.refunded': 'Refunded',
  'text.register.agreement': 'By signing up, you agree to the',
  'text.reloadGiftCard': 'Reload Gift Card',
  'text.removeCard.content': 'Are you sure you want to remove the card?',
  'text.removeCoupon': 'Remove {code}',
  'form.label.coupon.percentage': 'Percentage',
  'form.placeholder.percentage': '0.01 Percentage',
  'text.removeCoupon.description': 'Are you sure you want to remove this coupon?',
  'text.removeUser': 'Remove {name}',
  'form.label.coupon.type': 'Type',
  'text.removeUser.description': 'Are you sure you want to remove this user?',
  'text.resendGiftCard': 'Resend Gift Card',
  'text.role': 'Role',
  'text.savedCards': 'Saved Cards',
  'text.senderDetails': 'Sender Details',
  'text.serialNo': 'S.No.',
  'text.signIn.title': 'Sign in to your account',
  'text.status': 'Status',
  'text.stepper.businessProfile': 'Business Profile',
  'text.stepper.subscription': 'Subscription',
  'text.storeDetails': 'Store Details',
  'text.subscribe': 'Subscribe',
  'text.subtotal': 'Subtotal',
  'text.summaryPeriod': '{from} to {to}',
  'text.updateCoupon': 'Update Coupon',
  'text.updateLocation': 'Update Location',
  'text.updateUser': 'Update User',
  'text.usageCount': 'Usage Count',
  'text.user': 'User',
  'text.userDetails': 'User Details',
  'text.userList': 'User List',
  'text.users': 'Users',
  'text.transactionHistory': 'Transaction History',
  'text.transactionType': 'Transaction Type',
  'text.totalDue': 'Total Due',
  'text.totalCard': 'Total Card',
  'text.totalCash': 'Total Cash',
  'text.totalCount': '{count, plural, =1{1 record} other{# records}}',
  'text.totalDiscount': 'Total Discount',
  'text.totalGiftSales': 'Total Gift Sales',
  'text.totalGiftRedeemed': 'Total Gift Redeemed',
  'text.totalPaid': 'Total Paid',
  'text.totalRedeemedCount': 'Total Redeemed Count',
  'text.totalSoldCount': 'Total Sold Count',
  'text.value': 'Value',
  'text.freePhysicalGiftCards': 'Free Physical Gift Cards',
  'text.freePhysicalGiftCards.content':
    "You've used {usedCount} out of {availableCount} physical gift cards.",

  'title.billing.planInventory': 'Plan and Inventory',
  'form.placeholder.percent': '0',
  'title.billing.paymentHistory': 'Payment History',
  'text.percentage': 'Percentage',
  'text.flatRate': 'Flat Rate',
  'title.business': 'Business',
  'title.confirmEmail': 'Confirm Email',
  'title.login': 'Login',
  'title.manage.coupon': 'Manage Coupons',
  'title.manage.customer': 'Manage Customers',
  'title.manage.draftemail': 'Manage Draft Mail',
  'title.manage.giftCard': 'Manage Gift Cards',
  'title.manage.location': 'Manage Locations',
  'title.manage.user': 'Manage Users',
  'title.recoverPassword': 'Recover Password',
  'title.resetPassword': 'Reset Password',
  'title.register': 'Register',
  'title.setup.integrations': 'Integrations',
  'title.setup.giftCard': 'Gift Card Setup',
  'title.setup.store': 'Store Setup',
  'title.summary.redeem': 'Redeemed Summary',
  'title.summary.sales': 'Sales Summary',
  'title.email.create-email': 'Create Email',
  'title.email.drafts': 'Drafts',
  'title.email.history': 'Email History',

  'topBar.issuesHistory': 'Issues History',
  'topBar.projectManagement': 'Project Management',
  'topBar.typeToSearch': 'Search...',
  'topBar.findPages': 'Find pages...',
  'topBar.actions': 'Actions',
  'topBar.status': 'Status',
  'topBar.profileMenu.hello': 'Hello',
  'topBar.profileMenu.billingPlan': 'Billing Plan',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.business': 'Business',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone',
  'topBar.profileMenu.editProfile': 'Edit Profile',
  'topBar.profileMenu.logout': 'Logout',

  unauthorized_access: 'Unauthorized Access!',
  unauthorized_access_desc: "Sorry, you don't have access to this page.",
  go_back_home: 'Go Back to Home',
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}
