const actions = {
  SET_STATE: 'coupon/SET_STATE',
  SET_ERROR_STATE: 'coupon/SET_ERROR_STATE',
  EDIT_DATA: 'coupon/EDIT_DATA',
  REMOVE_DATA: 'coupon/REMOVE_DATA',
  SHOW_CREATE: 'coupon/SHOW_CREATE',
  HIDE_CREATE: 'coupon/HIDE_CREATE',
  SHOW_UPDATE: 'coupon/SHOW_UPDATE',
  HIDE_UPDATE: 'coupon/HIDE_UPDATE',
  SHOW_DELETE: 'coupon/SHOW_DELETE',
  HIDE_DELETE: 'coupon/HIDE_DELETE',

  LOAD_ALL: 'coupon/LOAD_ALL',
  CREATE: 'coupon/CREATE',
  UPDATE: 'coupon/UPDATE',
  REMOVE: 'coupon/REMOVE',
}

export default actions
