import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { load, update, getcustomerlist } from 'api/customer'
import actions from './actions'

export function* LOAD({ payload }) {
  const { pagelimit, pagesize, search } = payload

  yield put({
    type: actions.SET_STATE,
    payload: {
      pagelimit,
      pagesize,
      search,
      pageLoader: true,
    },
  })

  const { response } = yield call(load, payload)

  yield put({
    type: actions.SET_STATE,
    payload: {
      pageLoader: false,
    },
  })

  if (response) {
    const { customers } = response.data

    yield put({
      type: actions.SET_STATE,
      payload: {
        data: customers.customers,
        currentpage: customers.current_page,
        totalpagesize: customers.total_page_size,
        totalcount: customers.total_count,
        search: customers.search,
      },
    })
  }
}

export function* UPDATE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { id, updatedData } = payload
  const { response } = yield call(update, id, updatedData)

  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    const { customer } = response.data
    yield put({
      type: actions.EDIT_DATA,
      payload: {
        data: customer,
      },
    })
  }
}

export function* GET_CUSTOMERS_LIST() {
  const { response } = yield call(getcustomerlist)
  if (response) {
    const { customers } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        data: customers,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD, LOAD),
    takeEvery(actions.UPDATE, UPDATE),
    takeLatest(actions.GET_CUSTOMERS_LIST, GET_CUSTOMERS_LIST),
  ])
}
