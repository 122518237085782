import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  importExternal,
  issuebulk,
  load,
  loadDigital,
  loadRedeemedSummary,
  loadSalesSummary,
  loadTransactions,
  redeem,
  reload,
  resend,
  redeemGiftCard,
} from 'api/gift-card'
import { uploadImage, loadsetting } from 'api/setting'
import { applycoupon } from 'api/coupon'
import { history } from 'index'
import actions from './actions'
import userActions from '../user/actions'

export function* LOAD() {
  const { response } = yield call(load)
  if (response) {
    const {
      gift_issued,
      gift_redeemed,
      gift_unredeemed,
      gift_cards,
      payment_mode,
      free_physical_card_count,
      used_free_physical_card_count,
    } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        giftIssued: gift_issued,
        giftRedeemed: gift_redeemed,
        giftUnredeemed: gift_unredeemed,
        data: gift_cards,
        paymentMode: payment_mode,
      },
    })
    yield put({
      type: userActions.UPDATE_BUSINESS_STATE,
      payload: {
        free_physical_card_count,
        used_free_physical_card_count,
      },
    })
  }
}

export function* LOAD_DIGITAL() {
  const { response } = yield call(loadDigital)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingDigitalCard: false,
    },
  })
  if (response) {
    const { reserved_gift_card } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        reservedGiftCard: reserved_gift_card,
      },
    })
  }
}

export function* LOAD_DIGITAL2() {
  const { response } = yield call(loadDigital)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingDigitalCard: false,
    },
  })
  if (response) {
    const { reserved_gift_card } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        reservedGiftCard2: reserved_gift_card,
        secondTime: true,
      },
    })
  }
}

export function* LOAD_TRANSACTIONS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      transactions: null,
    },
  })
  const { giftCardId } = payload
  const { response } = yield call(loadTransactions, giftCardId)
  if (response) {
    const { transactions, transaction_types } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        transactions,
        transactionTypes: transaction_types,
      },
    })
  }
}

export function* LOAD_SALES_SUMMARY({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingSalesSummary: true,
    },
  })
  const { response } = yield call(loadSalesSummary, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingSalesSummary: false,
    },
  })
  if (response) {
    const { summary, transactions } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        salesSummary: {
          summary,
          transactions,
        },
      },
    })
  }
}

export function* LOAD_REDEEMED_SUMMARY({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingRedeemedSummary: true,
    },
  })
  const { response } = yield call(loadRedeemedSummary, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingRedeemedSummary: false,
    },
  })
  if (response) {
    const { summary, transactions } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        redeemedSummary: {
          summary,
          transactions,
        },
      },
    })
  }
}

export function* ISSUEBULK({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      issuing: true,
    },
  })
  const { response } = yield call(issuebulk, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      issuing: false,
    },
  })
  if (response?.data?.is_gift_card_issued === false) {
    const giftCardResData = response?.data?.gift_cards?.filter(
      task => task.is_valid_gift_card === true,
    )
    if (giftCardResData?.length > 0) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          displayIssueDrawer: true,
          cardIssued: true,
          loadedCoupon: true,
          giftCardApiStatus: response?.data?.is_gift_card_issued,
          loadApiResult: true,
        },
      })
    } else {
      const giftCardResData2 = response?.data?.gift_cards?.filter(
        task => task.is_valid_gift_card !== true,
      )
      yield put({
        type: actions.SET_STATE,
        payload: {
          displayIssueDrawer: true,
          cardIssued: false,
          loadedCoupon: true,
          giftCardApiStatus: response?.data?.is_gift_card_issued,
          loadApiResult: true,
          is_valid_gift_card: giftCardResData2[0]?.is_valid_gift_card,
        },
      })
    }
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayIssueDrawer: false,
        loadedCoupon: false,
        giftCardApiStatus: response?.data?.is_gift_card_issued,
        couponResStatus: null,
      },
    })
    yield call(LOAD)
  }
}
export function* APPLY_COUPON({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      couponCode: payload?.code,
      couponAdding: true,
    },
  })
  const { response } = yield call(applycoupon, payload)
  if (response) {
    const { gift_cards, is_applied_coupon, card_type } = response?.data
    if (response?.data?.is_applied_coupon === true) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          cardTypeValue: card_type,
          couponResData: gift_cards,
          couponResStatus: is_applied_coupon,
          couponAdding: false,
          loadedCoupon: true,
        },
      })
    } else {
      yield put({
        type: actions.SET_STATE,
        payload: {
          cardTypeValue: card_type,
          couponResData: gift_cards,
          couponResStatus: is_applied_coupon,
          couponAdding: false,
          loadedCoupon: false,
          couponCode: null,
        },
      })
    }
  }
}

export function* RELOAD({ payload }) {
  const { reloadData } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      reloading: true,
    },
  })
  const { response } = yield call(reload, reloadData)
  yield put({
    type: actions.SET_STATE,
    payload: {
      reloading: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayReloadDrawer: false,
      },
    })
    yield call(LOAD)
  }
}

export function* LOADSETTING() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      data: null,
    },
  })
  const { response } = yield call(loadsetting)
  if (response) {
    const { setting, gift_card_images } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        data: setting,
        defaultImages: gift_card_images,
      },
    })
  }
}

export function* UPLOAD_IMAGE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      uploading: true,
    },
  })
  const { response } = yield call(uploadImage, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      uploading: false,
    },
  })

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayChooseImageModal: false,
        newlyUploaded: true,
        image: response.data.image,
      },
    })
  }
}

export function* RESEND({ payload }) {
  const { giftCardId, resendData } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      resending: true,
    },
  })
  const { response } = yield call(resend, giftCardId, resendData)
  yield put({
    type: actions.SET_STATE,
    payload: {
      resending: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayResendDrawer: false,
      },
    })
  }
}

export function* REDEEM({ payload }) {
  const { redeemData } = payload
  console.log('redeem data', redeemData)
  yield put({
    type: actions.SET_STATE,
    payload: {
      redeeming: true,
    },
  })
  const { response } = yield call(redeem, redeemData)
  yield put({
    type: actions.SET_STATE,
    payload: {
      redeeming: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayRedeemDrawer: false,
      },
    })
    yield call(LOAD)
  }
}

export function* CUSTOM_REDEEM({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      redeeming: true,
    },
  })
  const { response } = yield call(redeemGiftCard, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      redeeming: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayCustomRedeemDrawer: false,
      },
    })
    yield call(LOAD)
  }
}

export function* IMPORT_EXTERNAL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      importing: true,
    },
  })
  const { response } = yield call(importExternal, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      importing: false,
    },
  })
  if (response) {
    yield history.push('/manage/gift-card')
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD, LOAD),
    takeLatest(actions.LOAD_DIGITAL, LOAD_DIGITAL),
    takeLatest(actions.LOAD_DIGITAL2, LOAD_DIGITAL2),
    takeLatest(actions.LOAD_TRANSACTIONS, LOAD_TRANSACTIONS),
    takeLatest(actions.LOAD_SALES_SUMMARY, LOAD_SALES_SUMMARY),
    takeLatest(actions.LOAD_REDEEMED_SUMMARY, LOAD_REDEEMED_SUMMARY),
    takeLatest(actions.ISSUEBULK, ISSUEBULK),
    takeLatest(actions.RELOAD, RELOAD),
    takeLatest(actions.RESEND, RESEND),
    takeLatest(actions.REDEEM, REDEEM),
    takeLatest(actions.IMPORT_EXTERNAL, IMPORT_EXTERNAL),
    takeLatest(actions.UPLOAD_IMAGE, UPLOAD_IMAGE),
    takeLatest(actions.LOADSETTING, LOADSETTING),
    takeLatest(actions.APPLY_COUPON, APPLY_COUPON),
    takeLatest(actions.CUSTOM_REDEEM, CUSTOM_REDEEM),
  ])
}
