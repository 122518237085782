import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as ErrorImage } from 'assets/images/error_403.svg'

const Error403 = () => {
  return (
    <div className="container pl-5 pr-5 pt-5 pb-5 mb-auto text-dark font-size-32 text-center">
      <ErrorImage style={{ maxHeight: '200px', align: 'left' }} />
      <div className="font-weight-bold mb-3">
        <FormattedMessage id="unauthorized_access" />
      </div>
      <div className="text-gray-6 font-size-24">
        <FormattedMessage id="unauthorized_access_desc" />
      </div>
      <Link to="/" className="btn btn-primary mt-4">
        <FormattedMessage id="go_back_home" />
      </Link>
    </div>
  )
}

export default Error403
