const actions = {
  SET_STATE: 'payment/SET_STATE',

  LOAD: 'payment/LOAD',
  CONVERGE_PIN: 'payment/CONVERGE_PIN',
  UPDATE_CONVERGE: 'payment/UPDATE_CONVERGE',
  AUTH_CLOVER: 'payment/AUTH_CLOVER',
  UPDATE_CLOVER: 'payment/UPDATE_CLOVER',
}

export default actions
