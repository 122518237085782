const actions = {
  SET_STATE: 'subscription/SET_STATE',

  LOAD_PLAN_INVENTORY: 'subscription/LOAD_PLAN_INVENTORY',
  CREATE: 'subscription/CREATE',
  ADD_CARD: 'subscription/ADD_CARD',
  CHANGE_DEFAULT_CARD: 'subscription/CHANGE_DEFAULT_CARD',
  REMOVE_CARD: 'subscription/REMOVE_CARD',
  PURCHASE_GIFT_CARD: 'subscription/PURCHASE_GIFT_CARD',
}

export default actions
