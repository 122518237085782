import apiClient from 'services/axios'

const BASE_URL = 'coupon'

export async function loadAll(payload) {
  return apiClient
    .get(`${BASE_URL}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function create(payload) {
  return apiClient
    .post(`${BASE_URL}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function update(couponId, payload) {
  return apiClient
    .put(`${BASE_URL}/${couponId}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function remove(couponId, payload) {
  return apiClient
    .delete(`${BASE_URL}/${couponId}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function validate(code, payload) {
  return apiClient
    .post(`${BASE_URL}/${code}/validate/in-store`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function applycoupon(payload) {
  const { code } = payload
  delete payload.code
  return apiClient
    .post(`${BASE_URL}/${code}/validate/multiplecards`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
