import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import businessType from './business-type/reducers'
import country from './country/reducers'
import user from './user/reducers'
import menu from './menu/reducers'
import business from './business/reducers'
import location from './location/reducers'
import payment from './payment/reducers'
import giftCard from './gift-card/reducers'
import customer from './customer/reducers'
import setting from './setting/reducers'
import staff from './staff/reducers'
import coupon from './coupon/reducers'
import settings from './settings/reducers'
import subscription from './subscription/reducers'
import invoice from './invoice/reducers'
import emailMarketing from './email-marketing/reducers'
import RootAction from './actions'

export default history => {
  const appReducer = combineReducers({
    router: connectRouter(history),
    businessType,
    country,
    user,
    menu,
    business,
    location,
    payment,
    giftCard,
    customer,
    setting,
    staff,
    coupon,
    settings,
    subscription,
    invoice,
    emailMarketing,
  })
  return (state, action) => {
    if (action.type === RootAction.CLEAR_STATE) {
      state = undefined
    }
    return appReducer(state, action)
  }
}
