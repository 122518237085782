import apiClient from 'services/axios'

const BASE_URL = 'customer'
const BASE_URL1 = 'customer/loadCustomersPaging'
const BASE_URL2 = 'customer/getCustomersEmail'

export async function load(payload) {
  const { pagelimit, pagesize, search } = payload
  return apiClient
    .get(`${BASE_URL1}?limit=${pagelimit}&pagesize=${pagesize}&search=${search}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function update(customerId, payload) {
  return apiClient
    .put(`${BASE_URL}/${customerId}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getcustomerlist() {
  return apiClient
    .get(`${BASE_URL2}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
